import React from 'react';
import { Container } from '@material-ui/core';
import { OutboundLink } from 'gatsby-plugin-google-gtag';
import cx from 'classnames';
import styles from './SectionPlus3Pay.module.scss';

export default function SectionPlus3Pay() {
  return (
    <section className={styles.section}>
      <Container className={styles.container}>
        <p className={styles.headline}>
          分割手数料無料*の３回
          <br />
          あと払いも使える！
        </p>
        <small className={styles.small}>
          * 銀行振込・口座振替のみ分割手数料無料。
        </small>
        <OutboundLink
          href="https://paidy.com/landing/plus_3pay/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <button className={cx('btn long charcoal', styles.btn)}>
            ３回あと払いの詳細はこちら
          </button>
        </OutboundLink>
      </Container>
    </section>
  );
}
