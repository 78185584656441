import React from 'react';
import BoxIndex from '../../../BoxIndex/BoxIndex';
import styles from './SectionCampaignCondition.module.scss';

const CAMPAIGN_TITLE_CONTENTS = [
  {
    title: <strong>キャンペーン概要</strong>,
    content: (
      <>
        2022年3月15日（火）0:00～21日（月）23:59に、キャンペーン対象ショップでペイディの翌月あと払いもしくは3回あと払いを初めて利用し、一度に6,000円（税込）以上お買い物すると、一店舗ごとに500円キャッシュバック。
        <br />
        <br />
        ※キャンペーン期間中に上記条件でお買い物された方に、3月31日までにキャッシュバックを実施いたします。MY
        Paidyもしくはペイディアプリに反映されます。決済画面には反映されません。
      </>
    ),
  },
  {
    title: <strong>キャンペーン期間</strong>,
    content: <strong>2022年3月15日（火）0:00～21日（月）23:59</strong>,
  },
  {
    title: <strong>注意事項</strong>,
    content: (
      <>
        <>
          <ul className={styles.list}>
            <li>
              キャッシュバックは1加盟店につきお一人様1回までとさせていただきます。
            </li>
            <li>
              株式会社Paidyは本キャンペーンを、キャンペーン期間であっても予告なく変更または終了する場合がございます。
            </li>
            <li>
              不正と判断された場合や購入のキャンセルがあった場合は、キャッシュバックが取り消される場合がございます。
            </li>
            <li>
              その他条件は
              <a
                href="https://terms.paidy.com/cashback/"
                target="_blank"
                rel="noopener noreferrer"
              >
                キャッシュバック利用規約
              </a>
              に準じます。
            </li>
            <li>
              ペイディに関するお問い合わせはへご連絡ください。
              <a
                href="https://cs.paidy.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                ペイディヘルプページ
              </a>
              へご連絡ください。
            </li>
            <li>
              ビックカメラ.com、TSUKUMOにてあと払い（ペイディ）をご利用いただくには、ペイディアプリと、運転免許証もしくはマイナンバーカードの登録によるペイディプラスへのアップグレードが必要です。
            </li>
            <br />
          </ul>
        </>
      </>
    ),
  },
];

const SectionCampaignCondition = () => {
  return (
    <BoxIndex sectionStyle={{ maxWidth: 1230 }}>
      <div className={styles.campaignWrapper}>
        {CAMPAIGN_TITLE_CONTENTS.map((campaign, i) => (
          <div key={i} className={styles.campaign}>
            <div className={styles.title}>{campaign.title}</div>
            <div className={styles.content}>{campaign.content}</div>
          </div>
        ))}
      </div>
    </BoxIndex>
  );
};

export default SectionCampaignCondition;
