export const MERCHANTS = [
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-1.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-1.png'),
    destinationURL: 'https://fril.jp/',
    category: [1, 2, 4],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-2.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-2.png'),
    destinationURL: 'https://www.buyma.com/',
    category: [2, 3, 6],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-3.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-3.png'),
    destinationURL: 'https://www.biccamera.com/bc/main/',
    category: [4, 1, 6],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-4.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-4.png'),
    destinationURL: 'https://travel.willer.co.jp/',
    category: [5],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-5.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-5.png'),
    destinationURL:
      'https://www.magaseek.com/top/index/tp_1?cid=mgspaidycpn2203',
    category: [2, 3, 6],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-6.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-6.png'),
    destinationURL: 'https://i.morecon.jp/in2',
    category: [3],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-7.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-7.png'),
    destinationURL:
      'https://5-fifth.com/?utm_source=paidy_member&utm_medium=paidy&utm_term=20220315',
    category: [2, 6],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-8.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-8.png'),
    destinationURL:
      'https://www.neweracap.jp/collections/paidy-recommend?utm_source=Paidy&utm_medium=banner&utm_campaign=2203NewLife',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-9.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-9.png'),
    destinationURL: 'https://www.dreamvs.jp/',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-10.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-10.png'),
    destinationURL: 'https://www.soundhouse.co.jp/',
    category: [6, 7],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-11.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-11.png'),
    destinationURL: 'https://gocart.jp/',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-12.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-12.png'),
    destinationURL: 'https://www.yamada-denkiweb.com/',
    category: [4, 6, 7],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-13.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-13.png'),
    destinationURL: 'https://www.paul-joe-beaute.com/',
    category: [3],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-14.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-14.png'),
    destinationURL: 'https://annasui.co.jp/',
    category: [2, 6],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-15.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-15.png'),
    destinationURL: 'https://shop.tsukumo.co.jp/',
    category: [4, 6],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-16.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-16.png'),
    destinationURL: 'https://edwin-mall.jp/',
    category: [2],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-17.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-17.png'),
    destinationURL: 'https://punyus.jp/',
    category: [2, 7],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-18.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-18.png'),
    destinationURL: 'https://rezard.jp/apparel/',
    category: [2, 6],
  },
  {
    imgBg: require('src/images/campaign-newlife/banners/campaign-newlife-banner-19.png'),
    imgLogo: require('src/images/campaign-newlife/logos/campaign-newlife-logo-19.png'),
    destinationURL: 'https://www.unico-fan.co.jp/shop/default.aspx',
    category: [6, 4],
  },
];

export const TAGS = [
  { value: 0, label: 'すべて見る' },
  { value: 1, label: '総合通販' },
  { value: 2, label: 'ファッション' },
  { value: 3, label: 'コスメ・コンタクト' },
  { value: 4, label: '家電・家具' },
  { value: 5, label: 'バス予約・旅行' },
  { value: 6, label: '楽器・雑貨・インテリア' },
  { value: 7, label: 'その他' },
];
