import React from 'react';
import styles from 'src/components/Merchant/Campaign/Newlife/Newlife.module.scss';
import PaymentSteps from 'src/components/Merchant/Campaign/Newlife/PaymentSteps';
import SectionPlus3Pay from 'src/components/Merchant/Campaign/Newlife/SectionPlus3Pay';
import Layout from 'src/components/Layout/Layout';
import 'src/components/Layout/Layout.scss';
import { SITE_METADATA } from 'src/constants';
import SectionCampaignCondition from 'src/components/Merchant/Campaign/Newlife/SectionCampaignCondition';
import SectionMerchantGrid from 'src/components/Merchant/Campaign/Newlife/SectionMerchantGrid';
import SectionCampaignFooter from 'src/components/Merchant/Campaign/Newlife/SectionCampaignFooter';

const isProduction = process.env.GATSBY_ENVIRONMENT === 'production';

const Newlife = () => {
  return (
    <Layout
      SEOProps={{
        title: SITE_METADATA.merchantCampaignNewlife.title,
        description: SITE_METADATA.merchantCampaignNewlife.description,
        ogImage: require('src/images/campaign-newlife/newlife_ogp.png'),
        twitterImage: 'https://paidy.com/campaign-newlife/newlife_ogp_v2.png',
        appendedScripts: isProduction
          ? [
              {
                async: 'async',
                defer: 'defer',
                innerHTML: `
            !function(e,t,n,s,u,a){e.twq||(s=e.twq=function(){s.exe?s.exe.apply(s,arguments):s.queue.push(arguments);
            },s.version='1.1',s.queue=[],u=t.createElement(n),u.async=!0,u.src='//static.ads-twitter.com/uwt.js',
            a=t.getElementsByTagName(n)[0],a.parentNode.insertBefore(u,a))}(window,document,'script');
            // Insert Twitter Pixel ID and Standard Event data below
            twq('init','o6jrj');
            twq('track','PageView');
            `,
              },
            ]
          : [],
      }}
      hasNoHeader
      hasNoFooter
    >
      <div className={styles.topBanner} />
      <div className={styles.campaignDetail}>
        <div className={styles.campaignDetailInner}>
          <p className={styles.headline}>
            新生活をはじめるあなたを
            <br />
            ペイディが応援
          </p>
          <p className={styles.description}>
            対象ショップで初めてペイディを利用し、一度に6,000円以上（税込）
            <br />
            お買い物すると、一店舗ごとに500円キャッシュバック！
            <br />
            使うお店が増えれば増えるほど、どんどんお得に！
          </p>
        </div>
      </div>
      <PaymentSteps />
      <SectionPlus3Pay />
      <SectionCampaignCondition />
      <div className={styles.merchantTitle}>
        <p>新生活にエールキャンペーン</p>
        <p className={styles.pinkText}>対象ショップ</p>
      </div>
      <SectionMerchantGrid />
      <SectionCampaignFooter />
    </Layout>
  );
};

export default Newlife;
